import React from "react"
import styled from "styled-components"
import InputComponent from "../../components/InputComponent"
import DATATabLogo from "../../assets/logoSmallFarbig.svg"

function ThreeYears() {
  const redirectUrl = process.env.DATA_TAB_REDIRECT_URL
  const webhook = process.env.DATA_WEBHOOK_THREE_YEARS
  return (
    <Wrapper>
      <div className="input_wrapper_wrapper">
        <DATATabLogo className="datatab-logo-svg" />

        <InputComponent
          price="5999"
          purpose="DATATab For Three Years"
          webhook={webhook}
          redirectUrl={redirectUrl}
        />
      </div>
    </Wrapper>
  )
}

export default ThreeYears

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #018786;
  padding: 50px 0;

  .input_wrapper_wrapper {
    width: 70%;
    padding: 3rem;
    display: flex;
    flex-direction: column;

    @media (max-width: 991px) {
      padding: 0;
    }
    @media (max-width: 767px) {
      width: 80%;
      padding: 0;
    }
    @media (max-width: 479px) {
      width: 90%;
      padding: 0;
    }
    svg {
      width: 120px;
      height: 80px;
    }
  }
`
